import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const TosPage = () => {
    return (
        <Layout scroll={false} headerMode="no-quote">
            <SEO title="Terms of Service" />
            <article className="content">
                <header>
                    <h1>Terms of Service</h1>
                    {/* <small>Last Revised: November 23, 2023</small> */}
                    <p><strong>By confirming a booking with&nbsp; The Removal Man you are agreeing to the following terms and conditions.</strong></p>
                    <p>1) These terms and conditions apply to any and all contracts for the removal and/or storage of goods entered between The Removal Man and you the customer and they define and set out the rights, obligations, and responsibilities of both you and us under any such contract. These terms and conditions include provisions that limit our responsibilities and potential liability to you. We specifically draw your attention to these.</p>
                    <p>2) Where we use the word “you” or “your” it means the customer; “we”, “us” or “our” means The Removal Man. Where we use the word “goods” this refers to the items that are to be subject to the removal or storage, pursuant to this contract.</p>
                    <p>3) It is important that you read and understand the terms and conditions that will apply to any contract entered with us, before you commit yourself. If there is anything that you do not understand or do not wish to agree to, then please discuss it with us before signing and returning the quotation or otherwise entering a contract with us. Only enter into a contract if you wish to be bound by the terms and conditions set out below.</p>
                    {/* <p>We reserve the right to occasionally make changes to the content of this page. Please refer to the "Last Revised" date.</p> */}
                    <hr />
                    {/* <p>
                        If you have any question, please get in touch with us.
                    </p> */}
                </header>
                <section>
                    <p>OWNERSHIP OF THE GOODS<br />
                        IMPORTANT NOTE<br />
                            4) You hereby agree and confirm that you are either:<br />
                                (a) the owner of the goods; and/or<br />
                                    (b) are duly authorised by the owner or owners of the goods to enter into this contract on these terms and conditions for and on behalf of the owner(s).<br />
                                        5) You shall be responsible for any losses, expenses, or other costs incurred by us which are<br />
                                            caused by:<br />
                                                (a) an untrue statement made deliberately by you; and/or<br />
                                                    (b) the statement at clause 4 not being true.</p>
                    <p>GOODS THAT WE WILL NOT ACCEPT FOR REMOVAL OR STORAGE<br />
                                                    6) We will happily remove and store most items that you may wish to submit for removal or storage. However, we are, subject to clauses 7-9 below, unable in the normal course of business to accept any of the following types of items:<br />
                                                        (a) Any living thing, including any animals, birds, fish and plants;<br />
                                                            (b) Any goods that are likely to encourage vermin or other pests or to cause infestation;<br />
                                                                (c) Any goods that require a special licence;<br />
                                                                    (d) Any goods that require government permission to import or export;<br />
                                                                        (e) Any food and/or drink that is, or needs to be, refrigerated or frozen;<br />
                                                                            (f) Any drugs;<br />
                                                                                (g) Any stolen or prohibited or illegal goods;<br />
                                                                                    (h) Any goods that are potentially dangerous, explosive or otherwise liable to cause damage. This includes, but is not limited to, firearms, ammunition, paints, aerosols, gas bottles and canisters;<br />
                                                                                        (i) Any jewellery, including trinkets and watches, and precious stones or metals or items made from precious metals or including precious stones;<br />
                                                                                            (j) Any money, securities, financial or legal documents, including but not limited to share certificates and leases;<br />
                                                                                                (k) Any collections of stamps, coins or other similar collectable items.<br />
                                                                                                    7) If you intend to submit any such items for removal or storage you must declare this to us in advance of the removal or storage being undertaken. In such circumstances we shall not be obliged to remove or store the items in question unless we have confirmed in writing that we are willing to do so. If we do agree to remove or store the items in question we shall be entitled to charge extra in respect of the same. If you are not happy with the extra charge levied then you shall be entitled to terminate this contract, before the removal or storage commences.<br />
                                                                                                        8) If we do agree to accept such items for removal or storage then we shall not be liable for any loss or damage that occurs due to the special nature or sensitivities of the goods involved. Other than this exclusion we remain liable for other losses as we would under the balance of this contract.<br />
                                                                                                            9) If such items are removed or stored by us without our knowledge and written agreement to do so then we shall not be liable for any loss or damage to those items whatsoever including but not limited to that occurring due to the special nature or sensitivities of the goods involved. Furthermore in such circumstances you shall be responsible for any losses, expenses, or other costs incurred by us which are caused by the special nature of the items in question, such as but not limited to clean up costs and damage to other goods.</p>
                    <p>WHAT WE WILL DO<br />
                                                                                                            10) We shall:<br />
                                                                                                                (a) pack the goods, if requested and if we have agreed to do so;<br />
                                                                                                                    (b) remove them at the agreed time and date and deliver to the delivery address;<br />
                                                                                                                        (c) unpack them, if requested and if we have agreed to do so;<br />
                                                                                                                            (d) store them, if the contract provides for them to be stored.<br />
                                                                                                                                We shall at all times care for the goods whilst they are in our custody and control showing all reasonable care in the circumstances. We furthermore will endeavour to act and undertake our obligations at all times in a professional manner.<br />
                                                                                                                                    11) We shall only remove or deliver items into a loft, or similar space, if it is safe, which shall include but shall not be limited to being adequately lit and floored, and the access to it being safe.<br />
                                                                                                                                        12) We hold insurance, with a specialist insurer, to cover our potential liability to you for loss or damage to your goods or for losses arising from delay upto the level of liability under this contract. The insurance is subject to terms and policy excess. If you would like more detail as to the level of insurance we hold we would be delighted to refer you to our insurance brokers who will discuss the level of cover provided with you.</p>
                    <p>WHAT WE WILL NOT DO<br />
                                                                                                                                        13) There are certain things that we do not do and which are not covered under this contract. They are:<br />
                                                                                                                                            (a) dismantling or assembling flat pack furniture, or a property’s fitments or fittings;<br />
                                                                                                                                                (b) disconnecting or reconnecting any equipment or appliances;<br />
                                                                                                                                                    (c) securing or preparing for transit, as necessary, equipment or appliances, such as but not limited to securing washing machine drums;<br />
                                                                                                                                                        (d) taking up or laying fitted floor coverings of any kind;<br />
                                                                                                                                                            (e) removing storage heaters unless they are already disconnected and adequately dismantled;<br />
                                                                                                                                                                14) Our staff are not authorised or qualified to carry out such work. We recommend that a proper qualified person be separately employed by you to carry out these services.<br />
                                                                                                                                                                    15) We do not arrange insurance to cover your goods. We are unable to offer this service due to FSA regulation on the sale of insurance.</p>
                    <p>FORMATION OF THE CONTRACT<br />
                                                                                                                                                                    16) The quotation provided is for a fixed price for the work presented. It is based on our contracting to remove and/or store the goods subject to these terms and conditions.<br />
                                                                                                                                                                        17) The quotation will remain open for a period of 28 days from the date of the quotation. After then it shall be treated as having been automatically withdrawn. If you wish to proceed on the basis of our quotation then you must complete the quotation and any related documents (as advised to you) and return them to us with payment of a deposit. If, following our review of the documents that you provide, we are happy to proceed then we will send you our Confirmation. Your deposit will be returned if we decide not to enter a contract of removal/storage with you.<br />
                                                                                                                                                                            18) A legally binding contract will be formed when we send to you our Confirmation, and until that stage all discussions between us are strictly subject to contract. The contract will be subject to these terms and conditions. You should only sign and return the quotation and related documentation if you are happy with the quotation and these contract terms and conditions and wish to enter into a legally binding contract on these terms and conditions.<br />
                                                                                                                                                                                19) For the avoidance of doubt the following matters or circumstances have not, unless otherwise agreed in writing, been taken into account or included in the quotation:<br />
                                                                                                                                                                                    (a) The removal not commencing, other than because of our breach, within 3 months of acceptance of our Confirmation;<br />
                                                                                                                                                                                        (b) The work being undertaken on a weekend or public holiday;<br />
                                                                                                                                                                                            (c) Collection or delivery other than to the ground or first floor of a property;<br />
                                                                                                                                                                                                (d) Removal or storage of goods additional to those made known to us and upon which our quotation was based;<br />
                                                                                                                                                                                                    (e) Provision of extra services;<br />
                                                                                                                                                                                                        (f) Removal or storage of any goods mentioned at clause 6;<br />
                                                                                                                                                                                                            (g) Any costs, charges or fees that are incurred, including parking fees and permits, in undertaking any removal;<br />
                                                                                                                                                                                                                (h) Any property not having proper and/or adequate access. This includes, but is not limited to, not being able to park within 20 metres of the door to the property, the access being unsuitable for our vehicles or the property being inadequate for the free and easy movement of the goods into and within the property;<br />
                                                                                                                                                                                                                    (i) Changes to our costs due to changes in currency values, taxation or freight charges which are beyond our reasonable control;<br />
                                                                                                                                                                                                                        (j) Delays occurring for reasons that are outside our reasonable control;<br />
                                                                                                                                                                                                                            (k) the total value of the goods being in excess of £50,000.<br />
                                                                                                                                                                                                                                20) If the removal or storage does give rise to any of the matters set out at clause 19, and the quotation was not adjusted to take these into account, then you will pay to us the extra costs and expenses incurred because of the effect of those matters.<br />
                                                                                                                                                                                                                                    21) The quotation shall not under any circumstances include a charge for arranging or otherwise procuring insurance over the goods themselves during the removal or storage.</p>
                    <p>YOUR RESPONSIBILTY<br />
                                                                                                                                                                                                                                    22) Whilst we have many responsibilities under this contract there are some matters that you must be responsible for. These are:<br />
                                                                                                                                                                                                                                        (a) Declaring to us, in accordance with clause 46 and 47, the value of your goods if you wish us to consider accepting enhanced levels of liability for lost or damaged goods.;<br />
                                                                                                                                                                                                                                            (b) Being present, either yourself or through a representative, throughout collection and delivery of the goods;<br />
                                                                                                                                                                                                                                                (c) Checking that all the goods are both collected and delivered;<br />
                                                                                                                                                                                                                                                    (d) Checking that nothing is collected and/or delivered in error;<br />
                                                                                                                                                                                                                                                        (e) Where we have not agreed in writing to do so, obtaining all necessary permits, licences, customs documents etc that are necessary for the removal to take place. Where we have agreed in writing to obtain any such necessary permits, licences, customs documents (or some of them) to provide all requested information and documentation required to procure them promptly on request;<br />
                                                                                                                                                                                                                                                            (f) Preparing the property and goods for the removal, including but not limited to disconnecting all relevant appliances and electronic equipment and emptying and de-frosting any fridges and freezers and where we are not packing, taking all steps to ensure that the goods are packed, packaged and labelled in such manner that they can withstand normal handling during their removal;<br />
                                                                                                                                                                                                                                                                (g) Arranging proper security and protection for any goods that will be left in premises which will be unattended or to which third parties may have access either prior to collection or following delivery.<br />
                                                                                                                                                                                                                                                                    (h) Advising us if you consider that it will not be possible for vehicles typically used for household removals to be able to park within 20 metres of the door of the property or if access to a property is inadequate for the free and easy movement of the goods into and within the property.<br />
                                                                                                                                                                                                                                                                        23) You must, before the commencement of the removal, provide us with a contact address and contact details which we can use if we need to contact you during the removal and/or storage of the goods. If these details change you must inform us. If we contact you in writing using the details you have provided we will be entitled to assume that you have duly received any communication from us.<br />
                                                                                                                                                                                                                                                                            24) If you wish for your goods to be insured whilst being removed or stored you must arrange this. We cannot arrange such insurance due to FSA Regulation. However, we do maintain insurance of our own to cover our potential liability to you for loss and/or damage to your goods or for losses arising from delay upto the level of liability under this contract.<br />
                                                                                                                                                                                                                                                                                25) You shall be responsible for any losses, expenses or other costs incurred by us arising from your failure to attend to any of the matters set out at clauses 22-24 unless that loss was caused by our negligence.</p>
                    <p>POSTPONEMENT AND CANCELLATION<br />
                                                                                                                                                                                                                                                                                26) By agreeing to undertake the removal or storage we incur costs in preparing for it and may also lose the opportunity to undertake further work that would use the same resources. Because of this we may suffer loss if you cancel this contract or postpone its performance. The amount we will potentially lose will depend on when the cancellation and/or postponement occurs.<br />
                                                                                                                                                                                                                                                                                    27) If any deposit has been paid to book an appointment, it will not be given back in the event of a cancellation or postponment of such appointment. A new deposit will be charged for any new booking.<br />
                                                                                                                                                                                                                                                                                        </p>
                    <p>PAYMENT OF OUR CHARGES<br />
                                                                                                                                                                                                                                                                                                31) Clauses 32-37 set out the basis on which our charges must be paid, unless we agree otherwise.<br />
                                                                                                                                                                                                                                                                                                    32) Full payment of our agreed charges must be made at completion of the job.
                                                                                                                                                                                                                                                                                                        33) You will pay any other charges or monies that become due or payable under this contract within 30 days of us sending you an invoice or request for the same.<br />
                                                                                                                                                                                                                                                                                                            34) You will pay all storage charges one month in advance.<br />
                                                                                                                                                                                                                                                                                                                35) You are not entitled to withhold payment by reason of any claim, counterclaim or set-off.<br />
                                                                                                                                                                                                                                                                                                                    36) We accept most major debit and credit cards and are happy to accept payment in this way. A charge of 5% of the sum paid will apply to payments made with Amex or American Express cards. Where payment is made by electronic transfer you are responsible for all related banking charges, and should make allowance for this when making payment.<br />
                                                                                                                                                                                                                                                                                                                        37) Interest shall accrue and you shall pay it on all overdue or outstanding monies at a rate of 4% above the base rate of Royal Bank of Scotland plc.<br />
                                                                                                                                                                                                                                                                                                                            UNDERTAKING THE REMOVAL<br />
                                                                                                                                                                                                                                                                                                                                38) We will undertake the removal and storage with professional care and skill and taking reasonable account of all the circumstances. However, we specifically reserve the right to undertake the removal or storage in a manner that we think is appropriate including:<br />
                                                                                                                                                                                                                                                                                                                                    (a) sub-contracting all or part of the job to another removal or storage company;<br />
                                                                                                                                                                                                                                                                                                                                        (b) choosing the route we think is most effective; and<br />
                                                                                                                                                                                                                                                                                                                                            (c) using such vehicles, containers and methods of transport and/or storage as we believe are appropriate.<br />
                                                                                                                                                                                                                                                                                                                                                39) This does not however affect our responsibilities under this contract to take care of your goods and to provide the services within the appropriate timescales.</p>
                    <p>OUR LIABILTY FOR LOSS OR DAMAGE<br />
                                                                                                                                                                                                                                                                                                                                                40) We shall in so far as it results from negligence on our part and subject to the exclusions and limitations set out below, be responsible for any loss or damage done to the goods whilst they are in our custody and control under these terms and conditions.<br />
                                                                                                                                                                                                                                                                                                                                                    41a) We will not be held responsible for damange to goods that have not been packaged or unpackaged by us. If you suspect that any goods have been damaged, you have to check and verify this in front of a member of the removal team to claim damage. We will not be held responsible for any damage that you claim after the move is completed and the members of the removal team have left the property. 
                                                                                                                                                                                                                                                                                                                                                    41b) Other than when it results from negligence on our part we shall have no liability for loss or damage to the goods of any kind whatsoever.<br />
                                                                                                                                                                                                                                                                                                                                                        42) In all cases the value of the goods for the purposes of this contract and determining our liability shall be the current replacement cost of each item making up the goods, with due allowance for age, wear and tear and depreciation. For the avoidance of doubt goods shall not be valued on a “new for old basis”. Reference to the value of the goods within these terms and conditions shall be construed in accordance with this clause.<br />
                                                                                                                                                                                                                                                                                                                                                            43) If goods are lost or are damaged beyond repair then our liability will be, at most, the value of the goods lost or damaged beyond repair.<br />
                                                                                                                                                                                                                                                                                                                                                                44) If goods are damaged but can be repaired then our liability will be, at most, the repair cost. We shall not be liable for any diminution in value of the goods as a consequence of the repair.<br />
                                                                                                                                                                                                                                                                                                                                                                    45) Under no circumstances, other than provided for in clauses 46-49 below, shall our liability to you exceed £40 per item lost or damaged up to a maximum of £5,000 for any one removal/storage contract. For the avoidance of doubt where goods have been packed in a wardrobe, drawer, case, box or other type of container then the liability shall be £40 for the container and the items in it.<br />
                                                                                                                                                                                                                                                                                                                                                                        46) Prior to the removal you may ask us to agree to accept enhanced liability for lost or damaged goods, in which case the provisions of clauses 40, 41 and 45 will be amended in accordance with clause 49. Before we will accept enhanced liability you must provide a written declaration of the total value of your goods to be removed/stored. We will calculate our additional charges for enhancing our liability on that declaration and the declared sum will be our maximum liability to you for the loss or damage of your goods. Where we agree to accept enhanced liability this will be set-out in our Confirmation.<br />
                                                                                                                                                                                                                                                                                                                                                                            47) For certain removal destinations we will require you to complete and return to us a valued inventory of your goods, and any agreement to enhance our liability will be strictly conditional upon receipt of that valued inventory no later than the date upon which you pay the removal charges. If the total value of your goods on the valued inventory exceeds any declared value provided to us earlier then we shall treat that greater sum as the declared value and recalculate our charge for enhancing our liability.<br />
                                                                                                                                                                                                                                                                                                                                                                                48) Our enhanced liability is strictly conditional upon you paying, prior to the removal/storage of your goods, our additional charges for providing the enhanced liability facility.<br />
                                                                                                                                                                                                                                                                                                                                                                                    49) In cases where we do agree to enhance our levels of liability in accordance with clauses 46 to 48 the following shall apply:<br />
                                                                                                                                                                                                                                                                                                                                                                                        a) it shall not be necessary to establish that we have been negligent, for the purposes of clause 40 and clause 41 shall be excluded;<br />
                                                                                                                                                                                                                                                                                                                                                                                            b) the £40 per item limit within clause 45 shall not apply, and the limit of our liability shall be the value of the particular items lost or damaged, up to the total declared value;<br />
                                                                                                                                                                                                                                                                                                                                                                                                c) clause 58(a) shall not apply and in respect of goods that we have not packed (as defined in clause 58) we will not be liable for the loss of any item or items where the box or other article into which they were packed is delivered.<br />
                                                                                                                                                                                                                                                                                                                                                                                                    50) For the avoidance of doubt even in cases where enhanced liability has been agreed clauses 42 to 44 and 52 to 61 shall nevertheless apply, except where specifically excluded within clause 49(c).<br />
                                                                                                                                                                                                                                                                                                                                                                                                        51) The enhanced liability provided for by the mechanism within clauses 46 and 47 is not insurance of your goods.</p>
                    <p>WHAT WE WILL NOT BE LIABLE FOR<br />
                                                                                                                                                                                                                                                                                                                                                                                                        IMPORTANT NOTE. THESE TERMS EXCLUDE AND LIMIT OUR RESPONSIBILITIES AND LIABILTY TO YOU AND APPLY REGARDLESS OF WHETHER OR NOT YOU HAVE OPTED FOR ENHANCED LIABILITY<br />
                                                                                                                                                                                                                                                                                                                                                                                                            52) We shall not be responsible for any claim for loss or damage to the goods or for delay where the total claim amounts to less than a total of £100.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                (a) Any claim is subject to £100.00 cost on each and every claim or series of claims arising out of any one event. This means that you will be responsible for this sum in the event of a claim.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                    53) Where any item forms part of a pair or set we shall not be liable for more than the value of that particular item, without reference to any special value which such item may have as part of a pair or set.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                        54) We shall not under any circumstances be responsible or liable for any consequential or indirect losses, including but not limited to loss of profits or lost opportunity.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                            55) We shall not under any circumstances be liable for the diminution in value of damaged goods which are repaired.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                56) We will not be liable for losses arising from:<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                    (a) War, invasion, acts of foreign enemies, hostilities (whether declared or not), civil war, terrorism, rebellion and/or coup, Act of God, industrial action or other events outside our reasonable control;<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                        (b) Normal wear and tear, ageing, natural or gradual deterioration, leakage or evaporation;<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                            (c) Incidence of moths, vermin or similar infestation;<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                (d) Cleaning, repairing or restoring, unless we agreed to do the work;<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                    (e) Electrical or mechanical derangement to any appliance or equipment unless there is evidence of external impact damage;<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                        (f) Any inherent defect in the goods;<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                            (g) Changes of atmospheric or climatic conditions;<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                (h) Damage to motor bikes or other motor vehicles unless we have issued a pre-collection condition report;<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    (i) Damage to any motor bike or other motor vehicle moving under its own power other than during the normal course of loading and unloading.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        57) We will not be liable for:<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            (a) Loss or damage to the goods that occurs before we have taken possession, custody and control of the goods;<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                (b) Loss or damage to the goods that occurs after we have delivered or handed over the goods to you or to a party nominated by you;<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    58) Other than where we have packed and unpacked your goods (which includes goods packed in boxes, cases, cartons, luggage, wardrobes, drawers, or other type of container) we will not be liable for:<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        a) the loss of these goods or any item or items comprising part of the these goods;<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            b) damage to any item or items unless the outer-packaging has suffered external impact damage and the item or items were adequately packed and packaged to withstand normal handling.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                59) We shall not be responsible or liable if you submit for removal or storage any of the goods listed at clause 6, without our agreement, in accordance with clause 7. Such goods shall be removed or stored entirely at your risk.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    60) If we do agree, in accordance with clause 7, to remove or store any of the goods set out at clause 6 then our liability for loss and damage to them shall be limited as set out in clause 8.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        61) No employee of ours shall be separately liable to you for any loss, damage or delay or other breach of this contract.</p>
                    <p>DELIVERY DATES AND DELAYS IN TRANSIT<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        62) Where we advise you that your goods are to be carried as a “part load” or a “groupage consignment” this means that they will be carried for at least part of the journey in a container or vehicle with the goods of third parties. In such cases any transit period stated by us will be provided for guidance only and shall not be binding.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            63) Any transit periods expressed in days shall mean working days only and shall not include weekends or public holidays within the UK, at destination or in an intermediate transit country.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                64) We shall not be responsible under any circumstance for losses incurred for missed/cancelled travel arrangements including flights where a part load is quoted/confirmed, it is advised that all travel arrangements and flights are made after goods have been collected.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    65) If the collection or delivery of the goods is delayed or fails to take place at all we will, if the delay or failure arises from our negligence, pay your reasonable expenses that arise as a result of the delay or failure, such as but not limited to reasonable hotel costs. Under no circumstances shall we be responsible delays in delivery arising from the re-routing or delay in the voyage of an ocean-going vessel, whatever the reason for the delay or re-routing of the voyage.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        66) Under no circumstances shall our liability for delay under clause 65 exceed a total of £2000.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            67) Other than as set out in clauses 62 to 65 we shall not be responsible and shall not have to<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                indemnify or compensate you in respect of any costs or losses arising from delay or failure to deliver.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    68) If we are unable to deliver the goods we may take them into store. Other than where the delay results from our negligence the original removal and/or storage contract will then be fulfilled and any additional service(s), including storage and delivery, will be provided in accordance with these terms and conditions and at your expense.</p>
                    <p>DAMAGE TO ITEMS OR PROPERTY OTHER THAN THE GOODS BEING REMOVED OR STORED<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    69) We will not be liable for any damage whether to premises, property or the goods as a result of moving goods under your express instruction, and against our advice where moving the goods in the manner instructed will inevitably cause damage.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        70) In any event we shall only be liable for damage to premises or property, other than the goods being submitted for removal or storage, where such damage arises due to negligence on our part.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            71) Where damage to premises or property, other than the goods being submitted for removal or storage, does occur we must ask that you inform us immediately and note the damage on the worksheet or delivery receipt. In the absence of such notification we will not be able to accept any liability for the damage.</p>
                    <p>NOTIFICATION OF CLAIMS &amp; TIME LIMITS FOR CLAIMS<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            72) In cases where we deliver goods you must notify us of any visible loss, damage or failure to produce any goods at the time of delivery. This should be noted on the worksheet or delivery receipt.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                73) If we do not deliver then you must notify us of any visible loss, damage or failure to produce any goods at the time when you, or your representative, take possession of the goods. This should be noted on the worksheet or delivery receipt.</p>
                    <p>74) IMPORTANT: TIME LIMIT FOR NOTIFICATION OF CLAIMS OR COMMENCING SUIT<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                All claims for loss of or damage to goods must be notified to us before a member of the team leaves the premises and the job is completed. In a case of loss or non-delivery of all of the goods the claim must be notified within 7 days of the anticipated delivery date, or when you were informed of the loss, whichever is the earlier.</p>
                    <p>OUR RIGHT TO WITHHOLD OR DISPOSE OF THE GOODS (LIEN)<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        77) We may keep hold of all or some of your goods until you have paid all the charges you owe us, even if the unpaid charges do not relate to those goods.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            78) Furthermore we may sell all or some of your goods to recover any unpaid charges. We can only do this after giving you 30 days written notice that we intend to do so. If we do sell any of your goods, and the proceeds from the sale are greater than the amount you owe us, we will pay you any excess amount after deducting the cost of selling the goods.</p>
                    <p>ADVICE, INFORMATION AND THE BASIS OF AGREEMENT<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            79) In this document we set out the terms and conditions of the contract between us. Please do not rely on promises or claims, written or verbally made, that contradict the terms and conditions of this document. In the event of any apparent contradiction between these terms and conditions and a promise or claim made please refer to us for clarification.</p>
                    <p>LAW AND JURISDICTION<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                80) These terms and conditions and any contract entered by us shall be subject to English law and any claim arising from these terms and conditions or from any contract of removal or storage entered by us shall be subject to the exclusive jurisdiction of the English Courts.</p>
                    <p>EXTRA CONDITIONS THAT APPLY TO THE STORAGE OF GOODS<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    81) If you have failed to provide an address in accordance with clause 23 or if you fail to respond to our correspondence then we shall be entitled to publish notices in an area from which the goods were removed. The publication of such notice shall be accepted as valid communication with you.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        82) It is important that we both know what you have in store. Where we prepare an inventory of the goods taken into store and this is forwarded to you this must be checked. You must inform us, as soon as possible, of any inaccuracies. It shall be assumed that if you do not bring any inaccuracies to our attention within 7 days that you are in agreement with the contents of the inventory. The 7 day period can be extended if you request a time extension, within the 7 day period, and the same is confirmed in writing by us. We will not unreasonably refuse to grant such an extension of time.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            83) We reserve the right to review our storage charges periodically. We will inform you of any change in the applicable rates by giving you notice in writing not less than 28 days before the change in rates. The new rates will apply from the end of that 28 day period.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                84) We reserve the right to terminate the storage contract by giving you not less than 3 months notice in writing. If the goods have not been collected by you, or on your behalf, or delivered to you or to your order, by the time the storage contract terminates then the goods will thereafter only be held entirely at your risk and we will have no liability in respect of the same.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    85) If you wish to terminate the storage contract you may do so by giving us not less than 14 days notice in writing. Storage charges will then be payable up to the end of the notice period or the date on which the goods are removed, whichever is the later. All charges must be paid upto date before the goods can be released. Once the charges are paid we will endeavour to release the goods at a time that is convenient to you.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        86) If you decide to collect the goods rather than having them delivered then we reserve the right to charge a reasonable hand out charge for handing them over. You are reminded that our responsibility for the goods ends in accordance with clause 57. We must stress the importance of goods being properly checked by you on delivery or handing over to you or your representative.</p>
                    <p>EXTRA CONDITIONS THAT APPLY TO FOREIGN REMOVALS<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        87) The import of goods into many countries is heavily regulated. Unless we otherwise agree in writing it is your responsibility to complete all necessary documentation and obtain relevant permissions, permits, licences, customs documents or anything else necessary for the import and clearance of your goods.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            88) Where your goods are to be carried by sea you may become liable for General Average contribution (that is the costs incurred to preserve the carrying vessel and cargo in certain circumstances), salvage costs and costs of forward transmission. Unless we have agreed to enhanced liability in accordance with clauses 46 to 49 we shall not be liable to you for any liability whatsoever or howsoever arising, including by our negligence, that you may incur for salvage, General Average or forwarding costs.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                89) We will not accept liability for goods confiscated, seized or removed by Customs Authorities, or similar bodies and Government Agencies other than in circumstances where such confiscation, seizure or removal occurs due to our negligence.<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    90) You will be responsible for and obliged to pay on demand for any costs, charges (including but not limited to demurrage or storage charges), expenses, fines, penalties or similar cost incurred during the import, customs or similar procedure unless they were incurred as a consequence of negligence on our part in obtaining or arranging any permission, permit, licence or customs document for which we had agreed in writing to obtain or arrange.</p>
                    <p>GENERAL<br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    91) The provisions contained in this contract are considered fair and reasonable by the parties but if any provision shall be found to be unenforceable but would be valid if any part of it were deleted or modified, the provision shall apply with such modifications as may be necessary to make it valid and effective.</p>
                    <p>IMPORTANT NOTICE</p>
                    <p>THESE CONDITIONS HAVE BEEN PREPARED BY LEGAL REPRESENTATIVES OF The Removal Man &nbsp;AND AS SUCH ARE SUBJECT TO COPYRIGHT.</p>
                </section>
            </article>
        </Layout >
    )
}

export default TosPage
